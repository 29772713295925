import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';

function Header() {
  return (
    <Navbar expand="lg" style={{ backgroundColor: '#4E2D89' }}>
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            src="./stridecal-logo-simplified.svg"
            alt="StrideCal Logo"
            style={{ height: '40px' }}  // Adjust logo height for proper alignment
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/" style={{ color: 'white' }}>Home</Nav.Link>
            <Nav.Link as={Link} to="/Services" style={{ color: 'white' }}>Services</Nav.Link>
            <Nav.Link as={Link} to="/Products" style={{ color: 'white' }}>Products</Nav.Link>
            <Nav.Link as={Link} to="/AboutUs" style={{ color: 'white' }}>About Us</Nav.Link>
            <Nav.Link as={Link} to="/ContactUs" style={{ color: 'white' }}>Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
