import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const HomePage = () => {
  return (
    <Container fluid className="px-0">
      {/* Hero Section */}
      <Row className="min-h-200 align-items-center justify-content-center bg-light text-dark text-center py-5">
        <Col md={8}>
          <h1 className="display-4 font-weight-bold">Empowering Productivity</h1>
          <p className="lead">
            The StrideCal suite of AI-powered productivity tools ensures end-to-end human productivity, focusing on individuals, teams, and enterprises.
          </p>
        </Col>
      </Row>

      {/* Service Panels Section */}
      <Row className="justify-content-center py-5">
        {/* StrideCal Preppy */}
        <Col md={3} className="p-3">
          <div className="h-100 p-4 bg-white border rounded shadow-sm">
            <h3 className="text-dark font-weight-bold">StrideCal Preppy</h3>
            <p className="text-muted">
              A productivity tool designed for students, helping them manage multiple shifting priorities, academic pressures, and personal commitments.
            </p>
            <Link to="/products#stridecal-preppy" className="btn btn-primary mt-3">Learn More</Link>
          </div>
        </Col>

        {/* StrideCal Neuronimble */}
        <Col md={3} className="p-3">
          <div className="h-100 p-4 bg-white border rounded shadow-sm">
            <h3 className="text-dark font-weight-bold">StrideCal Neuronimble</h3>
            <p className="text-muted">
              Empowering individuals with health challenges like ADHD, early Alzheimer’s, and dementia by improving focus and mental clarity.
            </p>
            <Link to="/products#stridecal-neuronimble" className="btn btn-primary mt-3">Learn More</Link>
          </div>
        </Col>

        {/* StrideCal Liquimov */}
        <Col md={3} className="p-3">
          <div className="h-100 p-4 bg-white border rounded shadow-sm">
            <h3 className="text-dark font-weight-bold">StrideCal Liquimov</h3>
            <p className="text-muted">
              A personal digital organizer and automated planner for busy executives, ensuring they stay on top of tasks and manage time effectively.
            </p>
            <Link to="/products#stridecal-liquimov" className="btn btn-primary mt-3">Learn More</Link>
          </div>
        </Col>

        {/* StrideCal Enterprise */}
        <Col md={3} className="p-3">
          <div className="h-100 p-4 bg-white border rounded shadow-sm">
            <h3 className="text-dark font-weight-bold">StrideCal Enterprise</h3>
            <p className="text-muted">
              A comprehensive solution for enterprises to balance employee work-life while ensuring high productivity through data-driven insights and well-being metrics.
            </p>
            <Link to="/products#stridecal-enterprise" className="btn btn-primary mt-3">Learn More</Link>
          </div>
        </Col>
      </Row>

      {/* Footer Section to Align with ESG Goals */}
      <Row className="min-h-200 align-items-center justify-content-center bg-light text-dark text-center py-5">
        <Col md={8}>
          <h5 className="font-weight-bold">End-to-End Productivity Solutions</h5>
          <p className="lead text-muted">
            The StrideCal suite ensures measurable outcomes that align with ESG goals such as good health and well-being, and decent work and economic growth.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default HomePage;