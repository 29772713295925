import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Blog() {
  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <h1>Welcome to StrideCal</h1>
          <p>
            Blog
          </p>
          {/* Add more content here */}
        </Col>
      </Row>
    </Container>
  );
}

export default Blog;
