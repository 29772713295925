import React, { useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactUs = () => {
  const [captchaSolved, setCaptchaSolved] = useState(false);

  const onCaptchaChange = (value) => {
    if (value) {
      setCaptchaSolved(true);
    }
  };

  return (
    <Container fluid className="px-0">
      {/* Hero Section */}
      <Row className="min-h-200 align-items-center justify-content-center bg-light text-dark text-center py-5">
        <Col md={6}>
          <h1 className="display-4">Get in Touch</h1>
          <p className="lead">We're here to help and answer any questions you might have.</p>
        </Col>
      </Row>

      {/* Contact Information */}
      <Row className="justify-content-center py-5">
        <Col md={10}>
          <h2 className="text-center mb-4">Contact Information</h2>
          <Row>
            {/* Office Cards */}
            {[
              { title: 'Singapore (Registered Office)', address: '1 NORTH BRIDGE ROAD, #18-03, HIGH STREET CENTRE, SINGAPORE (179094)' },
              { title: 'India (Registered Office)', address: 'Plot no. 1/362, 4th Floor, Opp. Max Hospital, Sector-1, Vaishali, Ghaziabad, UP, 201010' },
              { title: 'Corporate Office (India)', address: 'G-5, Vardhman Tower, B-1, Janakpuri, New Delhi, 110058' },
              { title: 'Australia', address: '26 Thornton Street, Kensington SA - 5068' },
              { title: 'USA', address: '1215 Lakehurst Road, Livermore CA, 95441' },
            ].map((office, index) => (
              <Col md={6} lg={4} key={index} className="mb-4">
                <Card className="shadow-sm h-100">
                  <Card.Body>
                    <Card.Title>{office.title}</Card.Title>
                    <Card.Text>{office.address}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>

        </Col>
      </Row>
    </Container>
  );
};

export default ContactUs;
