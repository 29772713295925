import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Services = () => {
  return (
    <Container className="my-5">
      <Row className="text-center mb-4">
        <Col>
          <h1 className="display-4 font-weight-bold">Our Services</h1>
          <p className="lead text-muted">Discover the comprehensive range of services we offer to boost your productivity and innovation.</p>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="accordion" id="servicesAccordion">
            {/* Service Items */}
            {[
              {
                id: 'collapseOne',
                title: 'Software Solutions Architecture',
                description: 'We design robust and scalable software architectures using modern technologies such as Spring Boot, React, and Angular to ensure high availability, security, and performance.',
              },
              {
                id: 'collapseTwo',
                title: 'Mobile Application Development',
                description: 'Delivering high-performance mobile applications using Kotlin for Android and Flutter for cross-platform development, ensuring an optimized and user-friendly experience.',
              },
              {
                id: 'collapseThree',
                title: 'Big Data Analytics',
                description: 'We offer advanced data analytics services using Spark, Presto, and Apache Superset, helping organizations extract valuable insights from large-scale data sets.',
              },
              {
                id: 'collapseFour',
                title: 'Infrastructure Automation and DevOps',
                description: 'Our DevOps services include creating efficient CI/CD pipelines using Terraform, Ansible, Jenkins, and Kubernetes to automate your infrastructure management and deployment processes.',
              },
              {
                id: 'collapseFive',
                title: 'Public Cloud Architecture Consulting',
                description: 'We provide cloud architecture consulting for AWS, Azure, and Google Cloud Platform, helping organizations migrate and optimize their cloud environments.',
              },
            ].map((service, index) => (
              <div className="accordion-item bg-white border rounded shadow-sm mb-3 p-4" key={index}>
                <h2 className="accordion-header" id={`heading${index}`}>
                  <button
                    className="accordion-button collapsed bg-white border-0 text-dark font-weight-bold"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${service.id}`}
                    aria-expanded={index === 0 ? 'true' : 'false'}
                    aria-controls={service.id}
                  >
                    {service.title}
                  </button>
                </h2>
                <div id={service.id} className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`} aria-labelledby={`heading${index}`} data-bs-parent="#servicesAccordion">
                  <div className="accordion-body text-muted">
                    {service.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Services;